import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import Button from 'components/shared/Button'
import SEO from 'components/shared/SEO'
import bgImage from 'assets/images/hero_img.jpg'

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 110px);
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    z-index: -1;
    position: absolute;
    width: 450vw;
    height: 450vh;
    clip-path: ellipse(50% 58% at 50% 0%);
    ${({ theme }) => theme.mq.md} {
      clip-path: ellipse(50% 63% at 50% 0%);
      width: 300vw;
      height: 300vh;
    }
  }
  &:before {
    background: url(${bgImage}) no-repeat center;
  }
  &:after {
    background-color: rgba(0, 0, 0, 0.4);
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  text-align: center;
`

const Title = styled.h1`
  font-weight: ${({ theme }) => theme.superBold};
  font-size: ${({ theme }) => theme.fontSize.xlg};
  padding: 25px 10px 35px;
  text-transform: uppercase;
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`

const Heading = styled.h2`
  font-weight: ${({ theme }) => theme.superBold};
  font-size: ${({ theme }) => theme.fontSize.xl};
`

const StyledButton = styled(Button)`
  width: 260px !important;
  padding: 20px 30px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
`

const NotFound = () => (
  <Wrapper>
    <SEO title="Not Found" />
    <InnerWrapper>
      <Heading>404</Heading>
      <Title>Es gibt keine solche Seite</Title>
      <StyledButton as={Link} to="/">
        Gehen Sie zur Startseite
      </StyledButton>
    </InnerWrapper>
  </Wrapper>
)

export default NotFound
